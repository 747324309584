import { ButtonContent } from "../global/boton/ButtonContent";



const Block_estimate = ({ slogan, parafrase }) => {
    return (
        <div className="w-full bg2 flex  justify-center items-center md:h-[50vh] h-auto bg-cover bg-no-repeat"
            style={{ backgroundImage: `url("https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Background%2FRecurso%204.png?alt=media&token=5bc41a69-1744-4294-9d03-1807f5237e92")` }}>
            <div className="w-[100%] md:w-[80%] my-5 md:my-0 flex md:justify-start justify-center">
                <div className="md:w-1/2 w-[90%] h-full flex flex-col items-center">
                    <p className="text-[25px] text-white">
                        {slogan}
                    </p>
                    <p className="text-white">
                        {parafrase}
                    </p>

                    <div className="w-full flex justify-center py-5">
                        <ButtonContent btnStyle={'four'} />
                    </div>
                </div>
            </div>

        </div>
    )

};

export default Block_estimate